<template>
    <div class="tab-text">
        <div class="vx-row w-1/2 mb-6 mt-3 ml-5">
            <vs-button
                class="mt-2"
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-plus"
                @click="handleCreate()"
                v-if="this.$store.getters['user/hasPermissions']('create')"
            >Create</vs-button>
        </div>

        <div class="vx-row mb-12">
            <div class="core vx-col md:w-1/1 w-full mb-base">
                <data-table :baseUrl="baseUrl" :status="0"></data-table>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "../DataTable";
export default {
    components: {
        DataTable,
    },
    data() {
        return {
            title: "Bill Of Material",
            baseUrl: "api/wms/v1/item-bill-of-material",
            id: null,
            date: "",
        };
    },
    methods: {
        handleCreate() {
            this.$router.push({
                name: "bill-of-material-create",
            });
        },
    },
    mounted() {
      this.$store.dispatch("user/getPermissions", "bill-of-material");
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
